import { StaticImage } from 'gatsby-plugin-image';
import React, { useState }  from 'react'
import '../styles/brands.scss'


export default function Brands( ) {
   
    
    const [selectedIndex, setIndex] = useState(0);
    var Imgsrc=['/casino.png','/jazzyspin.png','/galaxy.png'];
    function toggleNav(event){
        event.preventDefault();
        
        var ele = document.getElementsByClassName('tab-collapse');
            
           for(let i=0;i<ele.length;i++)
           {

                if(ele[i].classList.contains('collapsing')){
                   
                    if(selectedIndex!==i)
                    {
                        setIndex(i);
                        return;
                    }
                    else{
                        setIndex(-1)
                    }
                
                }
           }
        
       
        
    }
    return (
       
        <div id='brand' className="brand-bg">

            <div className="py-5 container text-center">
                <h4 className="brand-text">Our Brands</h4>
                <div className="brand-heading mx-auto">
                  Delivering the Best and Most Innovative iGaming Experiences for Our Users
                </div>
            </div>
            <div className="container row brand-container overflow-hidden pb-5">
                <div className="column" >
                    <div className="accordion mb-0 ms-auto ps-md-0 px-0 px-md-4  acc-width" id="accordion" role="tablist" aria-multiselectable="true">
                        <div className="card">
                            <div style={ selectedIndex===0 ? { backgroundColor:'white',borderTopLeftRadius:'16px', borderTopRightRadius:'16px'} : {}} class="card-header p-0" role="tab" id="headingOne">
                                <h5 class="mb-0">
                                    <a onClick={toggleNav} style={ selectedIndex===0 ? { color:'black'}:{color:'#6A6A6A80'}} className=" brand-link collapsed text-decoration-none d-block pt-4 ps-4 pe-3 pb-2" data-toggle="collapse" href="#collapseOne" role="button" aria-expanded="true" aria-controls="collapseOne">
                                     CasinoRange
                                     <div style={ selectedIndex===0 ? { backgroundColor:'#F0F8FF'}:null}  className="arrow-icon">
                                         
                                         <i style={ selectedIndex===0 ? { transform:'rotate(-180deg)'}:null}  className="fa fa-angle-down"></i>

                                     </div>
                                    </a>
                                </h5>
                              
                            </div>
                            <div id="collapseOne" class="collapse show tab-collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body ps-4">
                                    <div className="brand-body">
                                      Established in 2018, CasinoRange set out to be the market leader and hub for online casino guides. 
                                      <br/>
                                      <br/>
                                      We’ve spent time crafting a one-stop-shop for all your online casino needs to make your gaming journey the best experience possible.
                                    </div> 
                                    <div className="d-flex flex-row mt-4">
                                        <div>
                                           <StaticImage placeholder="blurred"  alt="uk" className="round-img"   src="../../static/uk.png"/>
                                        </div>
                                        <div className="ps-3">  <StaticImage placeholder="blurred"  className="round-img" alt="rounded-circle"   src="../../static/us.png"/></div>
                                        <small className="ps-3 licensed-text" >Trusted and Licensed in the UK and USA</small>
                                    </div>
                                  
                                    
                                    <div className="hide-img-lg my-3" >
                                           {selectedIndex>=0?<img id="slide" className="brand-img img-fluid" alt="casino"  src={Imgsrc[selectedIndex]}/>:null}       
                                    </div>
                                    <div className="mt-3 mb-3 visit-text"><a rel="noopener noreferrer" target="_blank" href="https://casinorange.com/">Visit the website <img alt="arrow" width={30} height={30} className="ms-2" src="/arrow_right.png"/></a> </div>
                                </div>
                            </div>
                            {selectedIndex!==0?<hr/>:null}
                        </div>
                        <div className="card">
                            <div style={ selectedIndex===1 ? { backgroundColor:'white', borderTopLeftRadius:'16px', borderTopRightRadius:'16px'} : {}} class="card-header p-0" role="tab" id="headingTwo">
                                <h5 class="mb-0">
                                    <a onClick={toggleNav} style={ selectedIndex===1 ? { color:'black'}:{color:'#6A6A6A80'}} className="brand-link collapsed text-decoration-none d-block pt-4 ps-4 pe-3 pb-2" data-toggle="collapse" href="#collapseTwo" role="button" aria-expanded="false" aria-controls="collapseTwo">
                                      Jazzy  Spins
                                      <div style={ selectedIndex===1 ? { backgroundColor:'#F0F8FF'}:null} className="arrow-icon">
                                         
                                         <i style={ selectedIndex===1 ? { transform:'rotate(-180deg)'}:null}  className="fa fa-angle-down"></i>

                                     </div>
                                    </a>
                                </h5>
                            </div>
                            <div id="collapseTwo" class="collapse tab-collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div class="card-body ps-4">
                                                <div className=" brand-body">
                                                We offer an enthralling online casino experience with a staggering catalogue of 300+ games. 
                                                <br></br>
                                                <br></br>
                                                Add this to our fantastic bonuses and engaging promotions, it's sure to leave you feeling jazzy. 
                                                </div>
                                                <div className="d-flex flex-row mt-4">
                                                    <div>
                                                    <StaticImage placeholder="blurred" alt="uks" className="round-img"  src="../../static/uk.png"/>
                                                    </div>
                                                    <small className="ps-3 licensed-text" >Trusted and Licensed in the UK</small>
                                                </div>
                                                <div className="hide-img-lg my-3" >
                       
                                                       {selectedIndex>=0?<img id="slide" className="brand-img img-fluid" alt="jazzy"   src={Imgsrc[selectedIndex]}/>:null}       

                                                </div>
                                                <div className="mt-3 mb-3 visit-text"><a rel="noopener noreferrer" target="_blank" href="https://www.jazzyspins.com/">Visit the website <img alt="arrow" width={30} height={30} className="ms-2" src="/arrow_right.png"/></a></div>
                                        </div>
                            </div>
                            {selectedIndex!==1?<hr/>:null}
                        </div>
                        <div className="card ">
                            <div style={ selectedIndex===2 ? { backgroundColor:'white', borderTopLeftRadius:'16px', borderTopRightRadius:'16px'} : {}} class="card-header p-0" role="tab" id="headingThree">
                                <h5 class="mb-0">
                                    <a onClick={toggleNav} style={ selectedIndex===2 ? { color:'black'}:{color:'#6A6A6A80'}} class="brand-link collapsed text-decoration-none d-block pt-4 pb-2 ps-4 pe-3" data-toggle="collapse" href="#collapseThree" role="button" aria-expanded="false" aria-controls="collapseThree">
                                    Slots Galaxy Casino
                                    <div style={ selectedIndex===2 ? { backgroundColor:'#F0F8FF'}:null} className="arrow-icon">
                                         
                                         <i style={ selectedIndex===2 ? { transform:'rotate(-180deg)'}:null}  className="fa fa-angle-down"></i>

                                     </div>
                                    </a>
                                </h5>
                            </div>
                            <div id="collapseThree" class="collapse tab-collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion">
                                <div class="card-body ps-4">
                                    <div className=" brand-body">
                                    Our newly launched social gaming casino combines the online casino experience with the 'freemium' mobile gaming style adventure on both iOS and Android. 
                                    </div>
                                    <div className="d-flex flex-row mt-4">
                                        <div>
                                          <a rel="noopener noreferrer"  target="_blank" href="https://play.google.com/store/apps/details?id=com.olikaoneltd.slotsgalaxycasino&hl=en_GB&gl=US"> <StaticImage placeholder="blurred" alt="play" src="../../static/playstore.png"/></a>
                                        </div>
                                        
                                        <div className="ps-3">  <a rel="noopener noreferrer"  target="_blank" href="https://apps.apple.com/tt/app/slots-galaxy-casino/id1540812102"><StaticImage placeholder="blurred" alt="app" src="../../static/appstore.png"/></a></div>

                                    </div>
                                    <div className="d-flex flex-row mt-3">
                                        <div>
                                           <StaticImage placeholder="blurred" alt="globe" className="rounded-circle"  src="../../static/globe.png"/>
                                        </div>
                                        <small className="ps-3 licensed-text" >Trusted and Licensed Worldwide</small>
                                    </div>
                                    <div className="hide-img-lg my-3" >
                       
                                       {selectedIndex>=0?<img id="slide" className="brand-img img-fluid" alt="galaxy"   src={Imgsrc[selectedIndex]}/>:null} 
                                       
                                    </div>
                                    <div className="mt-3 mb-3 visit-text"><a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/tt/app/slots-galaxy-casino/id1540812102">Visit the website <img alt="arrow" width={30} height={30} className="ms-2" src="/arrow_right.png"/></a></div>
                                    
                               
                            </div>
                        </div>
                        {selectedIndex!==2?<hr className="mb-0"/>:null}
                </div>
               
                    </div>
                    
                </div>
                <div class="col-6 hide-img-sm" >
                        {selectedIndex===0?<img id="slide"
                             className="brand-img h-100"  alt="Brand-Desktop"  src={Imgsrc[0]}/>:null}
                        {selectedIndex===1?<img id="slide"
                             className="brand-img jazzy-img"  alt="Brand-Desktop"  src={Imgsrc[1]}/>:null}
                        {selectedIndex===2?<img id="slide"
                             className="brand-img h-100" alt="Brand-Desktop"  src={Imgsrc[2]}/>:null}
                </div>

            </div>
           
        </div>
       
    )
}