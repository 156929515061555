import React,{useEffect} from 'react'
import * as homeStyles from "../styles/home.module.scss"
import window from 'global'
import { Link  } from 'react-scroll'

const Home = () => {
  useEffect(() => {
    window.addEventListener('scroll', handle);
    var elem = document.getElementsByClassName('video-zoom')[0]
    if(elem!==undefined){
      elem.style.position= 'fixed'
    }
  }, [])
  function handle()
  {
     var scroll;
     if (window.pageYOffset !== undefined) {
        scroll=window.pageYOffset;
     } 
     else {
      var sy, d = document,
          r = d.documentElement,
          b = d.body;
          sy = r.scrollTop || b.scrollTop || 0;
          scroll=sy;
     }
    var elem = document.getElementsByClassName('white-circle')[0]
    if(elem!==undefined){
      elem.style.transform= 'translate3d(-50%, -'+(scroll/100)+'%, 0) scale('+(100 + scroll/5)/100+')'
      elem.style.webkitTransform='translate3d(-50%, -'+(scroll/100)+'%, 0) scale('+(100 + scroll/5)/100+')'

    }
    elem = document.getElementsByClassName('video-zoom')[0]
    if(elem!==undefined){
      elem.style.transform= 'translate3d(-50%, -'+(scroll/100)+'%, 0) scale('+(100 + scroll/5)/100+')'
      elem.style.webkitTransform='translate3d(-50%, -'+(scroll/100)+'%, 0) scale('+(100 + scroll/5)/100+')'
      elem.style.position= 'fixed'
    }
  
  }
 
    return (
        <div  id="home" className="mb-5">
          
         <div  className={homeStyles.zoom}>
             
           
                <div className="white-circle" id={homeStyles.circle}></div>
                <video className="video-zoom" autoplay="autoplay" loop muted playsInline preload="auto">
                    <source src="olika.mp4" type="video/mp4" />
                </video>
                <div className={homeStyles.hometext}>
                        Creating a Unique iGaming Experience With Innovative Partnerships
                </div>
                <div className={homeStyles.comapnytext}>
                        Digital Media Company
                </div>
                <div className={homeStyles.scrollposition}>
                    <div className={`w-100 pe-5 ${homeStyles.scroll}`}>
                    
                        <Link to="about" spy={true} smooth={true} duration={500} >
                                Scroll    
                        </Link> 
                        <Link to="about" spy={true} smooth={true} duration={500}><img className={`mt-4 ${homeStyles.bounce}`} alt="scroll_arrow"  src="/scroll_arrow.png"/> </Link>
                         
                    </div>
                </div>
          
         </div>
        </div>
    )
}

export default Home

