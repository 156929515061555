import React,{useEffect} from 'react'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import '../styles/skillsets.scss'
import { StaticImage } from "gatsby-plugin-image"

export default function Skillsets() {
    useEffect(() => {
        var ele = document.getElementsByClassName('nav-tabs'); 
        ele[0].setAttribute('id', 'nav-id')
    }, [])
    function handleSelect(key) {
        
        var ele = document.getElementById("nav-id");
      
       if(key==='affiliation'){
        ele.scrollLeft =ele.scrollWidth/100;   
       }
       else if(key==="content"){  
        
         ele.scrollLeft =ele.scrollWidth/5.2;
       }
       else if(key==="analytics"){
       
        ele.scrollLeft =ele.scrollWidth/2.9;   
       }
       else if(key==="ppc"){
        ele.scrollLeft =ele.scrollWidth/1.9;   
       }
       else if(key==="social"){
        ele.scrollLeft =ele.scrollWidth/1.6;   
       }
      
        
    }
    
    return (
        <div id="skillset" className="tabsBg">
            <div className="container py-5">
            
           

            
            <div className="pt-5 skillset-text"> Skillsets</div> 
            
           
              
          
            <Tabs onSelect={handleSelect}  className="as justify-content-start justify-content-md-center"  defaultActiveKey="affiliation" id="uncontrolled-tab-example">
          
                     
                <Tab  eventKey="affiliation" title="Affiliation">
                    <div  id="skillset_slide" className="my-5 mx-auto affiliation">
                        <div className="overflow">
                            <StaticImage  placeholder="blurred" alt="circle" className="circle-img" src="../../static/circles.png"/>
                        </div>
                        
                            <div className="white-box ps-5 pe-4 pe-md-2 ps-md-4 ">
                                <span className="blue-gradient">
                                Providing a custom made platform to deliver consistent results for our partners across the globe.
                                </span>
                            </div>
                       
                    </div>
                </Tab>
            <Tab eventKey="content" title="Content">
               
                <div id="skillset_slide"  className=" border my-5 mx-auto content">
                     <div className="overflow">
                       <StaticImage placeholder="blurred"  alt="circle" className="circle-img position"   src="../../static/content_circle.png"/>
                     </div>
                     
                       <div className="white-box ps-4 pe-2 ps-md-4">
                           <span className="orange-gradient">
                           Creating unique & engaging content for each of our partners to achieve optimum results. 

                           </span>
                      
                        </div>
                   </div>
                      
            </Tab>
             <Tab eventKey="analytics" title="Analytics" >
                <div id="skillset_slide" className="my-5 mx-auto  analytics">
                <div className="overflow">

                <StaticImage  placeholder="blurred"   alt="circle" className="circle-img" src="../../static/analytic_circle.png"
                />
                      </div>
                       <div className="white-box ps-4 pe-2 ps-md-4">
                       <span className="blue-gradient">
                       Using our data driven approach to build a custom strategy, based on specific business needs. 
                       </span>
                    </div>
                </div>
            </Tab>
            <Tab eventKey="ppc" title="PPC">
                 <div id="skillset_slide"  className="my-5 mx-auto  ppc">
                 <div className="overflow">

                 <StaticImage  placeholder="blurred" alt="circle" className="circle-img position" src="../../static/ppc_circle.png"
                     
                      />
                      </div>
                      
                       <div className="white-box ps-4 pe-2 ps-md-4 py-md-5 py-4">
                       <span className="orange-gradient">
                       Our iGaming PPC experts use best techniques to maximize the success for our partners.
                       </span>
                    </div>
                      
                      
                 </div>
           </Tab>
          <Tab eventKey="social" title="Social Media">
                <div id="skillset_slide"  className="my-5  mx-auto  media">
                    <div className="overflow">
                    <StaticImage  placeholder="blurred"  alt="circle" className="circle-img"  src="../../static/media_circle.png"/>
                    </div>
                    <div className="white-box ps-4 pe-2 ps-md-4">
                        <span className="blue-gradient">
                         Crafting captivating posts to increase brand notoriety on social networks to maximize ROI & Growth.
                        </span>
                    </div>
                </div>
          </Tab>
 
        
</Tabs>  
</div>

            

            </div>
           
           
        
    )
}
