import React from 'react'
import '../styles/about.scss'
import { Link  } from 'react-scroll'


export default function About() {
    return (
        <div id="about" className="row about-container mx-auto gx-0 ">
            <div className="col-12 col-md-6 mb-0 item  mb-md-5">
                <div className="d-flex flex-column about-content ">
                    <div className="align-self-center align-self-md-end">
                        <div className="text-start page-name">About</div>
                        <div className="text-start question-text">What We Do?</div>
                        <div className="mt-4 about-text text-start ">
                            Experts in the iGaming industry, our team focus on the customer acquisition through several marketing channels, to bring together creative and innovative minds to bring our brands to life. Our data driven approach ensures the success of our customers, as well as giving the best experience to our players.
                        </div>
                        <div className="text-color mt-5 learn-link">
                      
                            <Link spy={true} smooth={true} duration={500} to="skillset">Learn more <img alt="down-arrow" width={20} height={20} className="ms-2" src="/arrow_down.png"/> </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 item col-md-6">
                <div className="d-flex justify-content-end justify-content-md-center">
                        <div className="position-relative overflow-hidden">
                            <img  className="logo mb-5" src="/Elipse.png"
                                alt="logo"
                                />
                            <img className="mobilelogo"  src="/aboutPage.png"
                                alt="mobile"
                            />

                        </div>
                </div> 
            </div>
           
           
          
            
        
        </div>

     
        
    )
}
