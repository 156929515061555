import * as React from "react"
import 'font-awesome/css/font-awesome.min.css';
import Layout from "../components/layout"
import About from "../components/about"
import Home from "../components/home"
import Skillsets from "../components/skillsets";
import Brands from "../components/brands";
import Careers from "../components/careers";
import Contact from "../components/contact";
import loadable from '@loadable/component'
const Partners = loadable(() => import('../components/partners'))

// const About =lazy(()=> import('../components/about'))
// const Home =lazy(()=> import('../components/home'))
// const  Skillsets=lazy(()=> import('../components/skillsets'))
// const Brands =lazy(()=> import('../components/brands'))
// const  Careers=lazy(()=> import('../components/careers'))
// const Contact =lazy(()=> import('../components/contact'))


const IndexPage = () => {
        
return(
  
   <>
      <Layout class="layout">

               <Home />
               <About/> 
               <Skillsets />
               <Brands />
               <Partners />
               <Careers />
               <Contact />
            
      </Layout>
   </>


)
  
}

export default IndexPage


