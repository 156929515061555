import React,{useState} from 'react'
import Recaptcha from 'react-recaptcha';
import '../styles/contact.scss'
import axios from 'axios'
var validator = require("email-validator");

export default function Contact() {
    const [isVerified, setIsVerified] = useState(false);
    const [formValue, setFormValue] = React.useState({
        name: "",
        email:"",
        company:"",
        phone:"",
        message:"",
    })
    const [errors, setErrors] = React.useState({})
    const [sendStatus,setsendStatus]=useState(0)

    function changehandler(e){
        setFormValue({...formValue,[e.target.name]:e.target.value})
    }
    var callback = function () {
       // console.log('Done!!!!');
    };
    var verifyCallback = function (response) {
        if(response){
            setIsVerified(true)
            setErrors({...errors,
                recaptcha: ""
            })
        }
    };
    var expiredCallback = function () {
        setIsVerified(false)
    };
    function handleSubmit(event){
        event.preventDefault();
        if(validator.validate(formValue.email))
        {
            if(isVerified===true){

                    setErrors({})
                    axios.post("https://olika.redx.online/api/contactus/",formValue,{headers: { 'Content-Type': 'application/json'}})
                    .then(response => {
                        setsendStatus(1)
                        setTimeout(function(){ 

                            setsendStatus(0);
                            setFormValue({
                                name: "",
                                email:"",
                                company:"",
                                phone:"",
                                message:"",

                            })
                        }, 3000);

                    })
                    .catch(error => {
                        setsendStatus(2)
                        setTimeout(function(){ 
                            setsendStatus(0);
                        }, 3000);
                    })
           }
           else{
            setErrors({...errors,
                recaptcha: "Incorrect Recaptcha."
            })

           }
        }
        else{
            setErrors({...errors,
                email: "Please fill in correctly the e-mail",
            })

        }
       
    }
    return (
        <div id="contact" className="row gx-0 pt-5 contact-container mx-auto">
            <div className="col-md-6 d-flex flex-column align-items-center text-center text-md-start">
                <div>
                    <div className="contact-text mt-2">Contact</div>
                    <div className="mt-2 contact-heading">Reach Out to Us</div>
                    <div className="mt-4 contact-body">
                    Thank you for showing an interest in our company, should you wish to get in contact please leave your details and we'll get back to you.
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <form  onSubmit={handleSubmit} className="mt-4 mt-md-0">
                    <div className="row gx-0">
                        <div class="mb-3 col-12 col-md-6 ps-3 field-width">
                            <label  htmlFor="name" className="form-label" for="InputName" class="form-label">Name<span>*</span></label>
                            <input value={formValue.name} name="name" onChange={changehandler} type="text" class="form-control" id="InputName" placeholder="Name" aria-describedby="nameHelp" required/>
                        </div>
                        
                        <div class="mb-3 col-12 col-md-6 ps-3 field-width">
                            <label  htmlFor="company"  className="form-label" for="InputComapany" class="form-label">Company</label>
                            <input value={formValue.company} name="company" onChange={changehandler} type="text" class="form-control" id="InputCompany" placeholder="Company Name"  aria-describedby="companyHelp"/>
                        </div>
                   
                    </div>
                    <div className="row gx-0 mt-2">
                       <div class="mb-3 col-12 col-md-6 ps-3 field-width">
                            <label  htmlFor="email"  className="form-label" for="InputEmail" class="form-label">Email<span>*</span></label>
                            <input value={formValue.email}  style={ errors.email? { border:'1px solid red',boxShadow:'none' } : {}} onChange={changehandler}  name="email" type="email" class="form-control" placeholder="E-mail Address"  id="InputEmail" aria-describedby="emailHelp" required/>
                            {errors.email?<div className="ps-1 mt-2 error-field">{errors.email}</div>:null}
                        </div>

                      
                        <div class="mb-3 col-12 col-md-6 ps-3 field-width">
                            <label  htmlFor="phone"  className="form-label" for="InputPhone" class="form-label">Phone</label>
                            <input value={formValue.phone} name="phone" onChange={changehandler} type="text" class="form-control" placeholder="Phone number"  id="InputPhone" aria-describedby="phoneHelp"/>
                        </div>
                   
                    </div>
                    <div >
                    <div class="mb-3 mt-2 contact-textarea ps-3">
                            <label  htmlFor="message"  for="exampleFormControlTextarea1" class="form-label ">Message<span>*</span></label>
                            <textarea value={formValue.message} name="message" onChange={changehandler} class="form-control" placeholder="Type a message..." id="exampleFormControlTextarea1" rows="6" required></textarea>
                             <div className="mt-3 ps-5 d-flex justify-content-center">
                                <Recaptcha
                                    sitekey="6Lcqc1cbAAAAABJhfBHQvtNJ5TrTr6xPy43oxcIP"
                                    render="explicit"
                                    verifyCallback={verifyCallback}
                                    onloadCallback={callback}
                                    expiredCallback={expiredCallback}
                                />
                            </div>
                           
                            <div className=" send-message-text text-center">
                            {errors.recaptcha?<div className="error-msg">{errors.recaptcha}</div>:null}
                            <div className="mt-3">
                            {sendStatus===0?<button type="submit">Send message<img alt="arrow-right" width={25} height={25} className="ms-2" src="/arrow_right.png"/></button>:null}
                            {sendStatus===1?<div className="status">Message successfully sent<img alt="arrow-success" width={20} height={15} className="ms-2" src="/success.png"/></div>:null}
                            {sendStatus===2?<div className="error-msg">Error, Please try resend later<img alt="arrow-success" width={20} height={15} className="ms-2" src="/error.png"/></div>:null}
                           

                            </div>
                            
                            
                           
                            </div>
                            
                    </div>
                    </div>
                   
                </form>
            </div>
            
        </div>
    )
}
