import { StaticImage } from 'gatsby-plugin-image'
import React,{useEffect,useState} from 'react'
import '../styles/careers.scss'
import axios from 'axios'


export default function Careers() {
   
  
    const [jobs, setjobs] = useState([]);
    useEffect(() => {
        axios.get("https://olika.redx.online/api/jobs/")
		.then(response => {
            setjobs(response.data)
		})
		.catch(function (error) {
            console.log(error)
			
		});
        
        
    },[])
  
    return (
      
        <div id="careers">
            <div className="py-5 container text-center">
                <h4 className="career-text mt-5">Careers</h4>
                <h2 className="mx-auto career-heading">
                Be a Part of Our Team
                </h2>
            </div>
            <div className="d-flex ps-auto ps-md-5 job-row-width">
               
                {jobs.map((job,index)=>(
                    
                     <div class="card rounded-3 position-relative job-card">
                         <div style={(job.position.toLowerCase()).indexOf('full')!==-1?{background:'linear-gradient(229.84deg, #DB55AD -11.79%, #FFBB54 102.72%)'}:{background:'linear-gradient(229.84deg, #F5C8FF -11.79%, #B650CB 6.78%, #55C2DB 99.7%, #F3FAFC 102.72%)'}} className="d-flex justify-content-end  card-bg card-header ">
                                 <h5 class="border job-name  my-2 px-3 py-2 text-white">{job.position}</h5>
                         </div>
                         <div className="job-logo px-1"><StaticImage placeholder="blurred"  src="../../static/logo.svg"/></div>
                         <div class="card-body  pt-5 job-body-shadow">
                                 <h5 data-toggle="collapse" data-target={`#demo${index}`} class="card-title mt-3 job-title">{job.title}</h5>
                                 <div id={`demo${index}`} class="collapse show">
                                 <p class="card-text mt-3">{job.description}</p>
                                <div className="my-3 apply-text"><a rel="noopener noreferrer" target="_blank" href={job.link}>Apply now <img alt="arrow" width={25} height={25} className="ms-2" src="/arrow_right.png"/></a></div>
                                 </div>
                         </div>
                     </div>
                    

                 ))}
                  {/* <div class="card rounded-3 position-relative job-card">
                        <div style={('internship'.toLowerCase()).indexOf('internship')!==-1?{background:'linear-gradient(229.84deg, #DB55AD -11.79%, #FFBB54 102.72%)'}:{background:'linear-gradient(229.84deg, #F5C8FF -11.79%, #B650CB 6.78%, #55C2DB 99.7%, #F3FAFC 102.72%)'}} className="d-flex justify-content-end  card-bg card-header ">
                                 <h5 class="border job-name  my-2 px-3 py-2 text-white">Internship</h5>
                         </div>
                         <div className="job-logo px-1"><StaticImage placeholder="blurred"  src="../../static/logo.svg"/></div>
                         <div class="card-body  pt-5 job-body-shadow">
                                 <h5 data-toggle="collapse"  class="card-title mt-3 job-title">Junior SEO Exeutive</h5>
                                 <div  class="collapse show">
                                 <p class="card-text mt-3">We are looking for an enthusiastic SEO Executive to join its dynamic team of digital marketing professionals</p>
                                <div className="my-3 apply-text"><a rel="noopener noreferrer" target="_blank" href="#">Apply now <img alt="arrow" width={25} height={25} className="ms-2" src="/arrow_right.png"/></a></div>
                                 </div>
                         </div>
                   </div> */}
                 
            </div>
           
           
    
            
        </div>
        
    )
}
